<template>
  <section class="searchField">
    <div class="text-center">
      <div class="search">
        <div class="container">
          <h2 class="text-light">
            DOCUMENTATION
          </h2>

          <b-form class="kb-search-input justify-content-center searchInput">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="knowledgeBaseSearchQuery"
                placeholder="Ask a question..."
              />
            </b-input-group>
          </b-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BForm, BInputGroup, BFormInput, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  name: 'Search',
  components: {
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
    }
  },
}
</script>

<style lang="scss" scoped>
.searchField {
  padding-top: 60px;
  background-image: url("../../assets/images/documentation.jpg");
  background-position: center;

  .search {
    padding-top: 60px;
    padding-bottom: 60px;

    .container {
      max-width: 700px;

      .text-light {
        font-family: 'Open Sans', sans-serif;
        font-size: 40px;
      }
    }
  }
}
</style>
