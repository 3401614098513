<template>
  <div>
    <div class="help">
      <Search />
      <section class="cards">
        <div class="container">
          <b-row class="kb-search-content-info match-height">

            <b-col
              lg="12"
              xl="4"
            >
              <b-card
                data-aos="zoom-in"
                data-aos-duration="1000"
                class="card"
                style="background-color: #1b1c1e"
              >
                <img
                  class="icon"
                  src="../assets/images/icons/folder.png"
                  alt=""
                >
                <h2
                  class="header"
                  style="color: darkorange"
                >
                  GENERAL
                </h2>
                <ul>
                  <li>
                    <router-link
                      class="link"
                      style="color: white"
                      :to="{ name: 'documentationGeneral', params: { pageProp: 'contact-us' } }"
                    >
                      How to contact us
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      style="color: white"
                      :to="{ name: 'documentationGeneral', params: { pageProp: 'product-images' } }"
                    >
                      How to import product images by EAN?
                    </router-link>
                  </li>
                </ul>
              </b-card>
            </b-col>

            <b-col
              lg="12"
              xl="4"
            >
              <b-card
                data-aos="zoom-in"
                data-aos-duration="1000"
                class="card"
                style="background-color: white;"
              >
                <img
                  class="icon"
                  src="https://img.icons8.com/ios/100/000000/folder-invoices--v1.png"
                  alt=""
                >
                <h2
                  class="header"
                  style="color: black;"
                >
                  SHIPMENTS
                </h2>
                <ul class="shipments">
                  <li>
                    <router-link
                      class="link"
                      style="color: black"
                      :to="{ name: 'documentationShipments', params: { pageProp: 'mark-products' } }"
                    >
                      How to mark products as completed or pending
                    </router-link>
                  </li>
                  <li style="height: 42px">
                    <router-link
                      class="link"
                      style="color: black"
                      :to="{ name: 'documentationShipments', params: { pageProp: 'product-labels' } }"
                    >
                      How to print product labels – Printer Configuration Guide
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      style="color: black"
                      :to="{ name: 'documentationShipments', params: { pageProp: 'box-size' } }"
                    >
                      How to add box size/weight?
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      style="color: black"
                      :to="{ name: 'documentationShipments', params: { pageProp: 'filters' } }"
                    >
                      How to use filters?
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      style="color: black;"
                      :to="{ name: 'documentationShipments', params: { pageProp: 'new-box' } }"
                    >
                      How to add a new box?
                    </router-link>
                  </li>
                </ul>
              </b-card>
            </b-col>

            <b-col
              lg="12"
              xl="4"
            >
              <b-card
                data-aos="zoom-in"
                data-aos-duration="1000"
                class="card"
                style="background-color: darkorange;"
              >
                <img
                  class="icon"
                  src="../assets/images/icons/folder.png"
                  alt=""
                >
                <h2
                  class="header"
                  style="color: white"
                >
                  LINNWORKS INTEGRATIONS
                </h2>
                <ul class="linnworks">
                  <li
                    v-b-toggle.collapse-1
                    class="link"
                  >
                    DB Schenker
                  </li>
                  <b-collapse id="collapse-1">
                    <ul>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationDbschenker', params: { pageProp: 'attaching-documents' } }"
                        >
                          Attaching documents to the shipment
                        </router-link>
                      </li>
                      <li style="height: 44px">
                        <router-link
                          class="link"
                          :to="{ name: 'documentationDbschenker', params: { pageProp: 'booking-webservice' } }"
                        >
                          Obtaining access key & subscribing to booking webservice
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationDbschenker', params: { pageProp: 'pricing-credentials' } }"
                        >
                          Obtaining pricing credentials
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationDbschenker', params: { pageProp: 'services-and-incoterms' } }"
                        >
                          Available services & Incoterms
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationDbschenker', params: { pageProp: 'address-id' } }"
                        >
                          Customer Address ID
                        </router-link>
                      </li>
                    </ul>
                  </b-collapse>

                  <li
                    v-b-toggle.collapse-2
                    class="link"
                  >
                    Mirakl
                  </li>
                  <b-collapse id="collapse-2">
                    <ul>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationMirakl', params: { pageProp: 'general' } }"
                        >
                          General information
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationMirakl', params: { pageProp: 'setup' } }"
                        >
                          How to setup the channel
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationMirakl', params: { pageProp: 'qa' } }"
                        >
                          Questions and Answers
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationMirakl', params: { pageProp: 'pricing' } }"
                        >
                          Pricing
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationMirakl', params: { pageProp: 'price' } }"
                        >
                          Price Change
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          class="link"
                          :to="{ name: 'documentationMirakl', params: { pageProp: 'configurator' } }"
                        >
                          Mirakl configurator
                        </router-link>
                      </li>
                    </ul>
                  </b-collapse>
                </ul>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCollapse, VBToggle,
} from 'bootstrap-vue'

import Search from '@/components/Help/Search.vue'

export default {
  name: 'Help',
  components: {
    Search,
    BCard,
    BRow,
    BCol,
    BCollapse,
  },

  directives: {
    'b-toggle': VBToggle,
  },
}
</script>

<style lang="scss" scoped>
.help {
  background-image: url("../assets/images/background.png");
  background-position: center;
  background-attachment: fixed;

  .cards {
    background-image: url("../assets/images/background.png");
    background-position: center;

    .container {
      padding: 25px 10px 0 10px;

      .card {
        box-shadow: 0 0 15px black;
        min-height: 307px;

        .icon {
          width: 36px;
          display: inline-block;
          margin-right: 20px;
          padding-bottom: 17px;
        }

        h2 {
          font-weight: bold;
        }

        .header {
          font-family: 'Open Sans', sans-serif;
          font-size: 21px;
          display: inline-block;
        }

        ul {
          padding-left: 27px;
          font-family: 'Barlow', sans-serif;
          font-size: 16px;
          font-weight: bold;
          list-style-image: url("../assets/images/icons/list.png");
          border-top: 3px solid white;
          margin-bottom: 10px;

          li {
            margin-top: 10px;
            margin-bottom: 10px;
            color: white;
            height: 24px;
          }

          .link {
            color: white;

            &:hover {
              font-size: 17px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
            }
          }

          .dropdown {
            ul {
              border: 0;
            }
          }
        }

        .shipments {
          border-color: black;
          list-style-image: url('../assets/images/icons/listDark.png');
        }

        .linnworks {
          list-style-image: url("../assets/images/icons/list-dropdown.png");
          cursor: pointer;

          ul {
            border-top: 0;

            li {
              height: 24px;

              .link {
                font-size: 16px;

                &:hover {
                  font-size: 17px;
                  transition: all 0.3s ease;
                }
              }

              .headerlink {
                font-size: 16px;

                &:hover {
                  font-size: 17px;
                  transition: all 0.3s ease;
                }
              }
            }
          }
        }
      }
    }

    .slide-enter, .slide-leave-to {
      overflow: hidden;
      max-height: 0;
    }

    .slide-enter-to, .slide-leave {
      max-height: 500px;
      overflow: hidden;
    }

    .slide-enter-active {
      transition: all 1s ease;
    }

    .slide-leave-active {
      transition: all 1s ease;
    }
  }
}
</style>
